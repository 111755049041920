$font-primary: 'Poppins', sans-serif;

$h1: 46px;
$h1-md: 38px;
$h2: 36px;
$h2-md: 28px;
$h3: 24px;
$h3-md: 22px;
$h4: 20px;
$h5: 18px;
$h6: 15px;

$white:#ffffff;
$black:#000000;
$dark:#1D1C21;
$light-gray:#F5F5F5;
$gray:#CECECE;

// Orange
//$color-primary:#FF3158;
$color-primary: #FF5721;
//light ornage
$color-secondary:#FFBB33;
//Teal
//$color-tertiary:#1E858B;
$color-tertiary: #7CB342;


$black-100:#111111;
$black-200:#222222;
$black-300:#333333;
$black-400:#444444;
$black-500:#555555;
$black-600:#666666;
$black-700:#777777;
$black-800:#888888;
$black-900:#999999;
