// button style
.content a {
	color: $black-300;
	&:hover {
		color: $color-primary;
	}
}
.content.content-active a {
	color: $color-primary;
}
.content.has-underdot-link a {
	border-bottom: 1px dashed;
	&:hover {
		border-bottom-style: solid;
		text-decoration: none;
	}
}
.btn {
	font-size: 15px;
	padding: 10px 20px;
	color: $white;
	border-radius: 5px;
	border: 0;
	font-weight: 500;
	transition: .3s;
	position: relative;
	&:active,
	&:focus {
		border: 0;
		box-shadow: none !important;
		border-color: $color-primary !important;
	}
	&.btn-sm {
		padding: 10px 25px;
	}
	&:hover {
		transform: translateY(-3px);
		.icon {
			background-color: darken($color-primary, 12);
			svg {
				transform: translateX(3px);
			}
		}
	}
	&.has-icon {
		padding: 14px 20px;
		padding-right: 60px;
		.icon {
			position: absolute;
			right: 0;
			top: 0;
			background-color: darken(desaturate($color-primary, 12.17), 4.71);
			height: 100%;
			width: 42px;
			padding: 0 8px;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			transition: 0.3s;
			overflow: hidden;
			svg {
				height: 100%;
				width: 27px;
				margin-left: -1px;
				transition: 0.3s;
			}
		}
	}
	&.btn-primary {
		background-color: $color-primary !important;
		&:hover,
		&:active,
		&:focus {
			color: $white;
			background-color: darken($color-primary, 5) !important;
		}
		&:hover {
			box-shadow: 0px 15px 35px rgba($color-primary, .3);
		}
	}
	&.btn-outline-primary {
		color: $black-200;
		background-color: transparent;
		border: 0.094rem solid $color-primary;
		&:active,
		&:hover,
		&:focus {
			color: $white;
			background-color: $color-primary !important;
		}
		&:hover {
			box-shadow: 0px 15px 35px rgba($color-primary, .3);
		}
	}
	&.btn-secondary {
		background-color: darken($gray, 20) !important;
		&:hover,
		&:active,
		&:focus {
			color: $white;
			background-color: darken($gray, 40) !important;
		}
	}
	&.btn-outline-secondary {
		color: $black-200;
		background-color: transparent;
		border: 0.094rem solid $gray;
		&:active,
		&:hover,
		&:focus {
			color: $white;
			border-color: rgba($color-tertiary, .5) !important;
			background-color: darken($gray, 20) !important;
		}
	}
	&.btn-link {
		color: $black-200;
		background-color: transparent;
		border: 0;
		svg {
			transition: transform .3s;
		}
		&:active,
		&:hover,
		&:focus {
			color: $color-primary;
			text-decoration: none;
			svg {
				transform: translateX(2px);
			}
		}
		&.text-primary:hover {
			color: darken($color-primary, 20) !important;
		}
	}
}