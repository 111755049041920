// banner style
.banner {
    padding-top: 75px;
    padding-bottom: 160px;
    overflow-x: hidden;
    @include breakAt(md-device) {
        padding-top: 40px;
    }
    .input-group {
        .form-control, button {
            border-radius: 5px !important;
        }
        @include breakAt(sm-device) {
            .form-control {
                width: 100%;
                margin-bottom: 10px;
            }
            button {
                margin-left: 0 !important;
                height: 50px;
                width: 100%;
            }
        }
    }
    .btn-outline-secondary {
		&:active,
		&:hover,
		&:focus {
            color: $black-600 !important;
            border-color: rgba($color-tertiary, .5) !important;
            background-color: transparent !important;
        }
    }
    .banner-image {
        margin-left: 50px;
        @include breakAt(lg-device) {
            margin-left: 0;
        }
        &.has-shapes {
            .shape-sm {
                left: 42%;
                top: 40px;
                @include breakAt(md-device) {
                    display: none;
                }
            }
            .shape-md {
                right: 7px;
                bottom: 30px;
                @include breakAt(md-device) {
                    display: none;
                }
            }
            .shape-lg {
                left: -10px;
                bottom: 20px;
                @include breakAt(md-device) {
                    display: none;
                }
            }
        }
    }
    .shape-1 {
        top: 15%;
        left: 20px;
    }
    .shape-3 {
        bottom: 150px;
        left: 15px;
    }
    .shape-5 {
        left: 32%;
        bottom: 100px;
    }
    .shape-6 {
        bottom: 130px;
        right: 30px;
    }
    .shape-4 {
        top: 50px;
        right: 20px;
    }
    .shape-8 {
        top: 130px;
        left: 35%;
    }
    .shape-7 {
        top: 80px;
        right: 40%;
    }
}

// page-header style
.page-header {
    height: 255px;
    padding-bottom: 45px;
    
    .shape-1 {
        left: 100px;
        top: 15px;
        height: 18px;
        width: 18px;
    }    
    .shape-2 {
        left: 25%;
        top: 50px;
        height: 12px;
        width: 12px;
        opacity: .5;
    }
    .shape-3 {
        left: 15%;
        bottom: 40%;
    }
    .shape-4 {
        left: 32%;
        bottom: 45%;
        height: 22px;
        width: 22px;
    }
    .shape-5 {
        right: 35%;
        top: 30px;
        height: 18px;
        width: 18px;
    }
    .shape-6 {
        right: 8%;
        top: 50px;
        @include breakAt(md-device) {
            display: none;
        }
    }
    .shape-7 {
        top: 15px;
        right: 19%;
        height: 9px;
        width: 9px;
        opacity: .7;
    }
    .shape-8 {
        bottom: 100px;
        right: 25%;
    }    
}

// video popup button style
.has-video-popup {
    position: relative;
    .video-play-btn {
        border: 0;
        outline: 0;
        height: 70px;
        width: 70px;
        padding: 2px 0 0 4px;
        position: absolute;
        top: 50%;
        left: 50%;
        color: $white;
        background-color: $color-primary;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
        box-shadow: 0 0 25px $white;
        @include breakAt(md-device) {
            height: 60px;
            width: 59px;
        }
        @include breakAt(xs-device) {
            height: 45px;
            width: 44px;
            svg {
                height: 1.4em;
                width: 1.4em;
                margin-top: -4px;
            }
        }
        &:hover {
            transform: translate(-50%, -50%) scale(1.2);
        }
        &::after,
        &::before {
            content: "";
            position: absolute;
            border-radius: 50%;
            border: 6px solid rgba($color-primary, .5);
            left: -20px;
            top: -20px;
            right: -20px;
            bottom: -20px;
            opacity: 0;
            backface-visibility: hidden;
            animation: pulse 2s linear infinite;
        }
        &::after {
            animation-delay: .5s;
        }
    }
    @keyframes pulse {
      0%{
        opacity: 0;
        transform: scale(.5);
      }
      50%{
        opacity: 1;
      }
      100%{
        opacity: 0;
        transform: scale(1.2);
      }
    }
}
#videoModal {
    .modal-dialog {
        max-width: 800px;
        margin: 30px auto;
    }
    .modal-body {
        position:relative;
        padding:0px;
    }
    .close {
        position:absolute;
        right: 15px;
        top:-40px;
        z-index:999;
        font-size:2rem;
        font-weight: normal;
        color:#fff;
        opacity:1;
    }
}

// brand carousel
.brand-carousel {
  .slick-list {
    margin-left: -17px;
  }

  .brand-item {
    height: 45px;
    margin-left: 17px;

    img {
      z-index: -1;
      transition: .3s ease-out;
      
      opacity: .5;
      //filter: grayscale(1);
      height: 45px;
    }

    &:hover {
      img {
        opacity: 1;
        filter: grayscale(0);
      }
    }
  }
}

// integrations carousel
.integrations-carousel {

    width: 450px;

  .slick-list {
    margin-left: -17px;
  }

  .integration-item {
    height: 160px;
    margin-left: 17px;

    img {
      z-index: -1;
      transition: .3s ease-out;

      //opacity: .5;
      //filter: grayscale(1);
      //height: 45px;
    }

    &:hover {
      img {
        opacity: 1;
        filter: grayscale(0);
      }
    }
  }
}

// how-it-works-tab
.how-it-works-tab {
  .nav-item:first-child {
    .nav-link::after,
    .nav-link::before {
      display: none;
    }
  }

  .nav-link {
    //font-weight: 600;
    font-weight: 500;
    position: relative;
    padding-top: 0;
    min-width: 180px;
    text-align: center;
    z-index: 1;
    color: $black-300;

    @include breakAt(lg-device) {
      min-width: 160px;
      max-width: 100%;
    }

    &::before,
    & .circle-shape::after,
    & .circle-shape::before {
      position: absolute;
      content: '';
    }

    & .circle-shape {
      position: relative;
      display: block;
      height: 32px;
      width: 32px;
      background-color: $gray;
      border-radius: 50px;
      margin: auto;
      margin-bottom: 10px;
      overflow: hidden;
      z-index: 2;

      &::before {
        background-color: $color-primary;
        height: 32px;
        width: 32px;
        top: 0;
        right: 0;
        border-radius: 50px;
        transition: .2s ease-out;
        transform: scale(0);
      }

      &::after {
        background-color: $white;
        height: 16px;
        width: 16px;
        top: 8px;
        left: calc(50% - 8px);
        z-index: 1;
        border-radius: 50px;
      }
    }

    &::before {
      width: 100%;
      height: 2px;
      background-color: #F7F7F7;
      left: calc(-50% + 16px);
      top: 16px;
      border-radius: 50px;
      transition: .3s;
      z-index: -1;

      @include breakAt(md-device) {
        display: none;
      }
    }

    &.active {
      background-color: transparent;
      color: $color-primary;

      & .circle-shape::before {
        transform: scale(1);
      }
    }
  }

  .tab-pane {
    &.active {
      .anim-to-top {
        animation: anim-to-top .7s;
      }

      .anim-to-bottom {
        animation: anim-to-bottom .7s;
      }
    }
  }

  @keyframes anim-to-top {
    0% {
      opacity: 0;
      transform: translateY(-15px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes anim-to-bottom {
    0% {
      opacity: 0;
      transform: translateY(15px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// growth-rate-counter
.growth-rate-counter {
    h2 {
        font-size: 32px;
    }
    .block {
        width: 110px;
    }
    .block-sm {
        width: 90px;
    }
    .block,
    .block-sm {
        @include breakAt(lg-device) {
            width: 20% !important;
        }
        @include breakAt(sm-device) {
            width: auto !important;
        }
    }
}
.has-colored-text {
    > div {
        &:nth-child(1) .has-text-color {
            color: $color-secondary;
        }
        &:nth-child(2) .has-text-color {
            color: $color-primary;
        }
        &:nth-child(3) .has-text-color {
            color: $color-tertiary;
        }
        &:nth-child(4) .has-text-color {
            color: $color-primary;
        }
        &:nth-child(5) .has-text-color {
            color: $color-tertiary;
        }
        &:nth-child(6) .has-text-color {
            color: $color-secondary;
        }



    }
}



  .colored-icon-box {
    .icon-box {
      transition: 0.3s;
      position: relative;
      z-index: 1;

      &::after {
        position: absolute;
        content: "";
        height: 0;
        width: 100%;
        border-radius: 5px;
        z-index: -1;
        background-color: #FAFAFA;
        bottom: 0;
        left: 0;
        transition: 0.3s;
      }

      .icon {
        font-size: 55px;
        display: inline-block;
        margin-bottom: 25px;
      }

      h4 {
        transition: color .3s;
      }

      &:hover {
        box-shadow: none !important;
        transform: translateY(2px);

        &::after {
          height: 100%;
        }
      }
    }

    > div {
      &:nth-child(1) .icon-box,
      &:nth-child(5) .icon-box {
        .icon {
          color: $color-primary
        }

        &:hover {
          &::after {
            background-color: rgba($color-primary, .05)
          }

          h4 {
            color: $color-primary
          }
        }
      }

      &:nth-child(2) .icon-box,
      &:nth-child(6) .icon-box {
        .icon {
          color: $color-tertiary
        }

        &:hover {
          &::after {
            background-color: rgba($color-tertiary, .05)
          }

          h4 {
            color: $color-tertiary
          }
        }
      }

      &:nth-child(3) .icon-box,
      &:nth-child(7) .icon-box {
        .icon {
          color: $color-secondary
        }

        &:hover {
          &::after {
            background-color: rgba($color-secondary, .1)
          }

          h4 {
            color: $color-secondary
          }
        }
      }

      &:nth-child(4) .icon-box,
      &:nth-child(8) .icon-box {
        .icon {
          color: #21435B
        }

        &:hover {
          &::after {
            background-color: rgba(#21435B, .05)
          }

          h4 {
            color: #21435B
          }
        }
      }
    }
  }
  // image-info-section style
  .image-info-section {
    .has-shapes {
      .shape-1 {
        bottom: 45px;
        left: 40%;

        @include breakAt(lg-device) {
          bottom: 20px;
        }
      }

      .shape-2 {
        top: 50px;
        right: 20px;
      }
    }

    .shape-3 {
      top: 210px;
      left: 70px;
    }

    .shape-4 {
      bottom: 150px;
      left: 20px;

      @include breakAt(md-device) {
        display: none;
      }
    }

    .shape-5 {
      top: 100px;
      right: 40px;

      @include breakAt(md-device) {
        display: none;
      }
    }

    .shape-6 {
      bottom: 30%;
      right: 40px;
    }

    .shape-7 {
      bottom: 100px;
      right: 20%;
    }
  }

  // testimonials-section style
.testimonials-section {
  .slick-list {
    //margin-left: -30px;
  }

  .testimonials-carousel {
    margin-right: 30px;

    .testimonials-item {
      margin-left: 30px;
    }
  }


  .testimonials-carousel {
    .testimonials-item {
      @for $i from 1 through 100 {
        &:nth-child(#{$i}) .icon {
          @if ($i % 3 == 1) {
            color: rgba($color-primary, .6);
          }
          @else if ($i % 3 == 2) {
            color: rgba($color-secondary, .6);
          }
          @else {
            color: rgba($color-tertiary, .6);
          }
        }
      }
    }
  }
  /*.testimonials-carousel {

    .testimonials-item {
      &:nth-child(1) .icon {
        color: rgba($color-primary, .6)
      }

      &:nth-child(2) .icon {
        color: rgba($color-secondary, .6)
      }

      &:nth-child(3) .icon {
        color: rgba($color-tertiary, .6)
      }

      &:nth-child(4) .icon {
        color: rgba($color-secondary, .6)
      }

      &:nth-child(5) .icon {
        color: rgba($color-tertiary, .6)
      }

      &:nth-child(6) .icon {
        color: rgba($color-primary, .6)
      }

      &:nth-child(7) .icon {
        color: rgba($color-primary, .6)
      }

      &:nth-child(8) .icon {
        color: rgba($color-secondary, .6)
      }

      &:nth-child(9) .icon {
        color: rgba($color-tertiary, .6)
      }

      &:nth-child(10) .icon {
        color: rgba($color-secondary, .6)
      }

      &:nth-child(11) .icon {
        color: rgba($color-tertiary, .6)
      }

      &:nth-child(12) .icon {
        color: rgba($color-primary, .6)
      }

      &:nth-child(13) .icon {
        color: rgba($color-primary, .6)
      }

      &:nth-child(14) .icon {
        color: rgba($color-secondary, .6)
      }

      &:nth-child(15) .icon {
        color: rgba($color-tertiary, .6)
      }
    }
  }
*/


  .testimonials-items > div {
    @for $i from 1 through 100 {
      &:nth-child(#{$i}) {
        .testimonials-item .icon {
          @if ($i % 3 == 1) {
            color: rgba($color-primary, .6);
          }
          @else if ($i % 3 == 2) {
            color: rgba($color-secondary, .6);
          }
          @else {
            color: rgba($color-tertiary, .6);
          }
        }
      }
    }
  }
  /* .testimonials-items > div {
    &:nth-child(1) {
      .testimonials-item .icon {
        color: rgba($color-primary, .6)
      }
    }

    &:nth-child(2) {
      .testimonials-item .icon {
        color: rgba($color-secondary, .6)
      }
    }

    &:nth-child(3) {
      .testimonials-item .icon {
        color: rgba($color-tertiary, .6)
      }
    }

    &:nth-child(4) {
      .testimonials-item .icon {
        color: rgba($color-secondary, .6)
      }
    }

    &:nth-child(5) {
      .testimonials-item .icon {
        color: rgba($color-tertiary, .6)
      }
    }

    &:nth-child(6) {
      .testimonials-item .icon {
        color: rgba($color-primary, .6)
      }
    }

    &:nth-child(7) {
      .testimonials-item .icon {
        color: rgba($color-primary, .6)
      }
    }

    &:nth-child(8) {
      .testimonials-item .icon {
        color: rgba($color-secondary, .6)
      }
    }

    &:nth-child(9) {
      .testimonials-item .icon {
        color: rgba($color-tertiary, .6)
      }
    }

    &:nth-child(10) {
      .testimonials-item .icon {
        color: rgba($color-secondary, .6)
      }
    }

    &:nth-child(11) {
      .testimonials-item .icon {
        color: rgba($color-tertiary, .6)
      }
    }

    &:nth-child(12) {
      .testimonials-item .icon {
        color: rgba($color-primary, .6)
      }
    }

    &:nth-child(13) {
      .testimonials-item .icon {
        color: rgba($color-primary, .6)
      }
    }

    &:nth-child(14) {
      .testimonials-item .icon {
        color: rgba($color-secondary, .6)
      }
    }

    &:nth-child(15) {
      .testimonials-item .icon {
        color: rgba($color-tertiary, .6)
      }
    }
  }
*/



  .testimonials-item {
    padding: 0;
    position: relative;
    margin-bottom: 30px;

    .block {
      position: relative;
      padding: 30px;
      padding-bottom: 25px;

      p {
        line-height: 1.5;
      }

      img {
        position: absolute;
        left: 20px;
        bottom: -14px;
        width: 32px;
        height: 14px;
      }
    }

    .user-info {
      padding-left: 7px;

      img {
        height: 60px;
        width: 60px;
      }
    }

    .border-bottom {
      padding-bottom: 20px;

      &::after {
        display: none;
      }
    }
  }

  .slick-dots li button:before {
    display: none;
  }

  .slick-dots {
    bottom: -45px;
    z-index: 2;

    li button {
      position: relative;
      height: 25px;
      width: 25px;

      &::after {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        border: 1px solid $black;
        border-radius: 50px;
        left: calc(50% - 7px);
        top: calc(50% - 7px);
        transform: scale(.5);
        transition: .3s;
      }
    }

    li.slick-active button::after {
      border-color: $color-primary;
      background-color: $color-primary;
      transform: scale(1);
    }
  }

  .shape-1 {
    top: 125px;
    left: 75px;
    z-index: 2;
  }

  .shape-2 {
    top: 125px;
    right: 75px;
    z-index: 2;
  }

  .shape-3 {
    left: 15px;
    top: 50%;
    height: 41px;
    width: 41px;
  }

  .shape-4 {
    right: 15px;
    top: 45%;
    height: 41px;
    width: 41px;
  }

  .shape-5 {
    bottom: 180px;
    left: 80px;
  }

  .shape-6 {
    bottom: 196px;
    right: 119px;
    height: 15px;
    width: 15px;
  }
}




  // tools-use-section style
  .tools-use-section {
    .inCircle-tools {
      padding: 180px 0;

      li img {
        max-width: 100%;
      }

      .has-centered {
        z-index: 99;
      }

      .lg {
        height: 180px;
        width: 180px;
      }

      .md {
        height: 114px;
        width: 114px;
      }

      .sm {
        height: 82px;
        width: 82px;
      }

      .xs {
        height: 72px;
        width: 72px;
      }

      @include breakAt(sm-device) {
        padding: 0;
        text-align: center;
        margin: 40px 0;

        li {
          position: static !important;
          height: 114px !important;
          width: 114px !important;
          padding: 0 15px;
          margin: 10px;
        }
      }
    }

    .shape-1 {
      top: 37%;
      left: 6%;
    }

    .shape-2 {
      bottom: 125px;
      left: 75px;
    }

    .shape-3 {
      left: 40%;
      top: 100px;
    }

    .shape-4 {
      left: 40%;
      bottom: 120px;
    }

    .shape-5 {
      top: 150px;
      right: 80px;
      height: 22px;
      width: 22px;
    }

    .shape-6 {
      bottom: 100px;
      right: 119px;
      height: 61px;
      width: 61px;
    }
  }
  // team-section style
  .team-filters {
    .btn {
      margin: 0 14px;
      color: $color-primary;
      border: 2px solid rgba($color-primary, 1);
      text-transform: capitalize;

      &:active {
        border-color: transparent !important;
      }

      &:hover,
      &.active {
        color: $white;
        background-color: rgba($color-primary, 1);
      }
    }
  }

  .team-item {
    .team-image {
      .team-shapes {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 2s linear;
      }

      .shape-1 {
        left: -13px;
        top: calc(50% - 13px);
        z-index: 1;
      }

      .shape-2 {
        right: 32px;
        top: 0;
        z-index: 1;
        height: 45px;
        width: 45px;
        z-index: 1;
      }

      .shape-3 {
        right: 19px;
        bottom: 20px;
        z-index: 1;
      }
    }

    &:hover {
      .team-shapes {
        transform: rotateZ(360deg);
        transition: transform 20s linear;
      }
    }
  }
  // pricing section style
  .pricing-switch {
    label {
      cursor: text;
      user-select: none;
      padding: 0 15px;
    }

    .toggle {
      height: 34px;
      cursor: pointer;
      z-index: 1;
      position: relative;

      .switch {
        display: inline-block;
        height: 100%;
        width: 70px;
        border: 1px solid $black-900;
        border-radius: 50px;
        position: relative;

        &::after {
          position: absolute;
          content: "";
          height: 24px;
          width: 24px;
          border-radius: 50px;
          background-color: $color-primary;
          left: 5px;
          top: 4px;
          z-index: 2;
          transition: 0.25s ease-in-out;
        }
      }

      .pricing-check:checked ~ .switch::after {
        left: 57.5%;
      }

      .pricing-check:active ~ .switch::after {
        transform: scale(0.8);
      }

      .pricing-check {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 3;
        opacity: 0;
        cursor: pointer;
      }
    }

    @include breakAt(sm-device) {
      text-align: center;
    }
  }

  .pricing-table {
    td.active {
      position: relative;
      overflow: hidden;

      .recomended {
        position: absolute;
        background: #dca71c;
        color: #ffffff;
        font-weight: 600;
        padding: 0 25px;
        transform: rotate(45deg);
        right: -29px;
        top: 26px;
        font-size: 10px;
        letter-spacing: 1px;
      }
    }

    td, th {
      border: 1px solid #EFEFEF;
      padding: 20px;
      width: 20%;
    }

    td span {
      vertical-align: middle;
    }

    thead td, thead th {
      border-bottom-width: 1px;
      background: $color-primary;
      border-color: #FF5F7D;
    }

    thead td:first-child, thead th:first-child {
      border-top-color: transparent;
      border-left-color: transparent;
      border-top-left-radius: 25px;
    }

    thead td:last-child, thead th:last-child {
      border-top-color: transparent;
      border-right-color: transparent;
      border-top-right-radius: 25px;
    }

    @include breakAt(lg-device) {
      display: block;

      thead, tbody, th, td, tr {
        display: block;
      }

      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr {
        border: 1px solid #ccc;
      }

      td, th {
        padding: 10px 20px;
        width: initial;
      }

      td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
      }

      td:before {
        position: absolute;
        top: 10px;
        left: 20px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
        content: attr(data-label);
      }
    }

    @include breakAt(sm-device) {
      td {
        padding-left: 40%;
      }

      td:before {
        width: 39%;
      }
    }
  }
  // accordion/FAQ section style
  .accordionFAQ {
    .card-header {
      position: relative;
      padding-left: 40px;
      cursor: pointer;
      user-select: none;

      @include breakAt(md-device) {
        padding-left: 30px;
      }

      h4 {
        transition: 0.3s;

        svg {
          color: #A1A1A1;
        }
      }

      .accordion-arrow {
        position: absolute;
        left: 0;
        transform: rotate(-90deg);
        transition: 0.3s;
        font-size: 16px;
        margin-top: 5px;
      }

      [aria-expanded="true"] {
        color: $color-primary;

        .accordion-arrow {
          transform: rotate(0);
          margin-top: 4px;
          color: $color-primary;
        }
      }
    }
  }
  // horizontal-accordion style
  .horizontal-accordion {
    .shape-1 {
      top: 125px;
      left: 75px;
    }

    .shape-2 {
      top: 125px;
      right: 75px;
    }

    .shape-3 {
      left: 15px;
      top: 50%;
      height: 41px;
      width: 41px;
    }

    .shape-4 {
      right: 15px;
      top: 45%;
      height: 41px;
      width: 41px;
    }

    .shape-5 {
      bottom: 180px;
      left: 80px;
    }

    .shape-6 {
      bottom: 196px;
      right: 59px;
      height: 15px;
      width: 15px;
    }
  }
  // life-at-gallery style
  .life-at-gallery {
    .shape-1 {
      top: 150px;
      left: 7%;
      height: 20px;
      width: 20px;
    }

    .shape-2 {
      top: 35%;
      left: 140px;
    }

    .shape-3 {
      top: 55%;
      left: 100px;
    }

    .shape-4 {
      bottom: 150px;
      left: 130px;
      height: 20px;
      width: 20px;
    }

    .shape-5 {
      top: 150px;
      right: 130px;
      height: 20px;
      width: 20px;
    }

    .shape-6 {
      bottom: 55%;
      right: 100px;
    }

    .shape-7 {
      bottom: 35%;
      right: 140px;
    }

    .shape-8 {
      bottom: 150px;
      right: 7%;
      height: 20px;
      width: 20px;
    }
  }
  // join-team-section style
  .join-team-section {
    .shape-1 {
      top: 150px;
      left: 7%;
      height: 20px;
      width: 20px;
    }

    .shape-2 {
      top: 35%;
      left: 140px;
    }

    .shape-3 {
      top: 55%;
      left: 50px;
    }

    .shape-4 {
      bottom: 150px;
      left: 130px;
      height: 20px;
      width: 20px;
    }

    .shape-5 {
      top: 150px;
      right: 15%;
      height: 20px;
      width: 20px;
    }

    .shape-6 {
      bottom: 55%;
      right: 100px;
    }

    .shape-7 {
      bottom: 35%;
      right: 140px;
    }

    .shape-8 {
      bottom: 150px;
      right: 7%;
      height: 20px;
      width: 20px;
    }
  }

  .modal.fade .modal-dialog {
    transform: translateX(0) scale(0.95);
  }

  .modal.show .modal-dialog {
    transform: translate(0) scale(1);
  }

  .modal-backdrop {
    background-color: #010e1d;

    &.show {
      opacity: .6;
    }
  }
  // terms navbar style
  .tabs-navbar.position-sticky {
    top: 0;
  }

  .tabs-navbar:not(.position-sticky) {
    @include breakAt(sm-device) {
      .nav-link,
      .nav-link:hover {
        padding: 10px 0 !important;
        border-radius: 5px;

        &.active {
          padding: 10px 15px !important;
          background-color: rgba($color-primary, 0.1);
        }
      }
    }
  }
  // service style
  .service-box {
    $service-box-border: 1px solid #E3E3E3;

    .block {
      border: $service-box-border;
      border-bottom: 0;
      border-left: 0;
    }

    &:nth-child(3) .block,
    &:nth-child(6) .block,
    &:nth-child(9) .block {
      border-right: 0;
    }

    @include breakAt(lg-device) {
      &:nth-child(2) .block,
      &:nth-child(4) .block,
      &:nth-child(8) .block,
      &:nth-child(10) .block {
        border-right: 0;
      }

      &:nth-child(3) .block {
        border-right: $service-box-border;
      }
    }

    @include breakAt(md-device) {
      .block {
        border: 0 !important;
      }
    }

    .service-icon {
      height: 85px;
      width: 85px;
      line-height: 90px;
      overflow: hidden;
      font-size: 35px;
    }

    &:nth-child(1) {
      .service-icon {
        background-color: rgba(#18C7D1, 0.15);
        color: $color-tertiary
      }
    }

    &:nth-child(2) {
      .service-icon {
        background-color: rgba(#FFBB33, 0.15);
        color: $color-secondary
      }
    }

    &:nth-child(3) {
      .service-icon {
        background-color: rgba(#FF3131, 0.15);
        color: $color-primary
      }
    }

    &:nth-child(4) {
      .service-icon {
        background-color: rgba(#3391FF, 0.15);
        color: #126dd6
      }
    }

    &:nth-child(5) {
      .service-icon {
        background-color: rgba(#9831FF, 0.15);
        color: #7714da
      }
    }

    &:nth-child(6) {
      .service-icon {
        background-color: rgba(#1BAC18, 0.15);
        color: $color-tertiary
      }
    }

    &:nth-child(7) {
      .service-icon {
        background-color: rgba(#FFBB33, 0.15);
        color: $color-secondary
      }
    }

    &:nth-child(8) {
      .service-icon {
        background-color: rgba(#FF3131, 0.15);
        color: $color-primary
      }
    }

    &:nth-child(9) {
      .service-icon {
        background-color: rgba(#18C7D1, 0.15);
        color: $color-secondary
      }
    }
  }
  // blog style
  .post-category {
    line-height: 1;

    &::before {
      position: absolute;
      content: "";
      height: 100%;
      width: 4px;
      left: -15px;
      top: 0;
      background-color: $black-200;
      transition: 0.3s;
    }

    &:hover {
      &::before {
        background-color: $color-primary;
      }
    }
  }

  .post-blockquote {
    padding: 35px 80px;
    background-color: #F8F8F8;
    margin: 40px 0 30px 0;
    border-radius: 15px;

    h3 {
      line-height: 32px;
      position: relative;
      padding-left: 10px;

      &:after, &:before {
        position: absolute;
        content: "";
        height: 30px;
        width: 33px;
        background-size: contain;
        background-repeat: no-repeat;
      }

      &::after {
        background-image: url(../images/blogs/details/quote-right.png);
        right: -33px;
        bottom: -8px;

        @include breakAt(xs-device) {
          display: none;
        }
      }

      &::before {
        background-image: url(../images/blogs/details/quote-left.png);
        left: -45px;
        top: -7px;

        @include breakAt(xs-device) {
          left: 0;
          top: -30px;
        }
      }

      @include breakAt(sm-device) {
        padding-left: 0;
        padding-top: 20px;
      }
    }

    @include breakAt(xs-device) {
      padding: 25px;
      padding-top: 50px;
    }
  }
  // contact-section style
  .contact-section {
    .shape-1 {
      top: 30%;
      left: 6%;
      height: 45px;
      width: 45px;
    }

    .shape-2 {
      top: 60%;
      left: 4%;
      height: 20px;
      width: 20px;
    }

    .shape-3 {
      top: 30%;
      right: 6%;
      height: 45px;
      width: 45px;
    }

    .shape-4 {
      bottom: 40%;
      right: 2%;
      height: 20px;
      width: 20px;
    }
  }







.statistic-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*background-color: green;*/
}
.statistic-container .counter-icon {
  flex: 1 1 30%;
  /*background-color:red;*/
  margin-left:24px
}

.statistic-container img {
  padding-right: 10px;
 
}

.statistic-count-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1 70%;
}

.statistic-count-container  p {
  color: $color-primary;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 1.3;
}



.slick-prev:before, .slick-next:before {
  color: #FF5721;
  font-size: 40px;
}


.slick-prev, .slick-next {
  top: 20%;
}
.slick-prev {
  left: -45px;
}
.slick-next {
  right: -55px;
}


i.security-icon {
  color: #FF5721;
  font-size: 25px;
}
