/*------------------------------------------------------------------
 # header style
-------------------------------------------------------------------*/
.header-nav {
  z-index: 500;

  .navbar {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .nav-item {
    .nav-link {
      //font-weight: 600;
      font-weight: 500;
      font-size: 15px;
      color: $black-200 !important;
      padding-left: 17px !important;
      padding-right: 17px !important;
    }

    &:hover,
    &.active {
      .nav-link {
        color: $color-primary !important;
      }
    }
  }
}
@media (min-width:1200px) {
    .navbar .dropdown-menu {
        display: block;
        height: 0;
        overflow: hidden;
        width: 220px;
        transition: 0.2s;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0;
        background-color: transparent;
        z-index: 1;
        &::after {
            position: absolute;
            content: "";
            height: 0;
            width: 100%;
            background-color: $white;
            top: 0;
            left: 0;
            z-index: -1;
            transition: .3s ease-out;
            box-shadow: 0 10px 30px rgba(22,28,45,.10);
            transition-delay: padding .25s;
        }
        li {
            opacity: 0;
            visibility: hidden;
            transform: translateY(-5px);
        }
        li:first-child {margin-top: 15px}
        li:last-child {margin-bottom: 15px}
    }
    .navbar .dropdown:hover .dropdown-menu {
        height: auto;
        overflow: visible;
        &::after {
            height: 100%;
        }
        li {
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
            transition: 0.25s;
            transition-delay: .2s;
        }
    }
    .navbar .sub-dropdown {
        position: relative;
        .dropdown-submenu {
            position: absolute;
            display: block;
            z-index: 2;
            width: 90%;
            left: 100%;
            top: -10px;
            height: 0;
            overflow: hidden;
            transition: .3s;
            background-color: transparent;
            &::after {
                position: absolute;
                content: "";
                height: 0;
                width: 100%;
                background-color: $white;
                top: 0;
                left: 0;
                z-index: -1;
                transition: .3s ease-out;
                box-shadow: 0 10px 30px rgba(22,28,45,.10);
                transition-delay: padding .25s;
            }
            li {
                opacity: 0 !important;
                visibility: hidden !important;
                transform: translateY(-5px) !important;
                transition: 0s !important;
                transition-delay: 0s !important;
            }
            li:first-child {margin-top: 10px}
            li:last-child {margin-bottom: 10px}
        }
        &:hover .dropdown-submenu {
            height: auto;
            overflow: visible;
            &::after {
                height: 100%;
            }
            li {
                opacity: 1 !important;
                visibility: visible !important;
                transform: translateY(0px) !important;
                transition: 0.25s !important;
                transition-delay: .2s !important;
            }
        }
    }
}
.dropdown-menu {
    border: 0;
    margin-top: 0;
    padding: 0 8px;
}
.dropdown-submenu {
    list-style: none;
    display: none;
    padding: 0 8px;
    background-color: $white;
}
.dropdown-item {
    padding: 6px 12px;
    font-weight: 600;
    font-size: 15px;
}
.sub-dropdown.active,
.sub-dropdown.active > .dropdown-item,
.sub-dropdown:hover > .dropdown-item,
.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
    color: $color-primary;
    background-color: rgba($color-primary, 0.08);
    border-radius: 5px;
}
.sub-dropdown.active > .dropdown-item {
    background-color: rgba($color-primary, 0.02);
}
.dropdown-toggle {
    &::after {
        display: none;
    }
}
@include breakAt(xl-device) {
  .dropdown-menu {
    border: 0;
    padding: 0;
    text-align: left !important;
    background: rgba($color-primary, 0.05);
    border-radius: 6px;
  }

  .navbar {
    .navbar-nav {
      margin-top: 15px;
      width: 280px;
      max-width: 100%;
      margin-right: auto;
      align-items: initial !important;
    }

    .nav-item {
      display: block;
      text-align: left;
    }

    .nav-link {
      padding: 8px 4px !important;
    }

    .dropdown-menu {
      li:first-child {
        padding-top: 10px;
      }

      li:last-child {
        padding-bottom: 10px;
      }
    }
  }

  .navbar-collapse {
    position: fixed;
    background-color: $white;
    width: calc(100% - 30px);
    height: auto;
    max-height: calc(100% - 100px);
    z-index: 9999;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba($black, .1);
    padding: 5px 20px 28px;
    text-align: center;
    top: 90px;
    left: 15px;
  }

  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    transition-property: opacity, transform, -webkit-transform;
    -webkit-transform-origin: top;
    transform-origin: top;
  }

  .navbar-collapse.show {
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .navbar-collapse.collapsing {
    opacity: 0;
    -webkit-transform: scale(.95);
    transform: scale(.95);
  }

  .navbar-button {
    width: 280px;
    max-width: 100%;
    margin: 15px auto 0;
  }

  .dropdown-item {
    padding: 6px 18px;
  }
}
.navbar-toggler {
    .nav-toggle-icon {
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        transition: transform .4s;
        user-select: none;
        .top {
            stroke-dasharray: 40 121;
        }
        .bottom {
            stroke-dasharray: 40 121;
        }
        &.active {
            transform: rotate(45deg);
            .top {
                stroke-dashoffset: -68px;
            }
            .bottom {
                stroke-dashoffset: -68px;
            }
        }
        .line {
            fill: none;
            transition: stroke-dasharray .4s, stroke-dashoffset .4s;
            stroke: $black-300;
            stroke-width: 5.5;
            stroke-linecap: round;
        }
    }
}
